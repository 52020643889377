/*
 * Author: Carlos Limão
 * Copyright 2016 Bitstuff
 */

'use strict';
var AppiFilters = (function($, window, document, undefined) {

    // Public properties
    var pub = {};

    // Private properties
    var dtTable = null;
    var tableName = null;

    /**
     * Checks if there are any filters active and updates user message.
     */
    function updateFiltersStatus() {
        if (
            // Filter by Events or Fees selected
            $('#select_fees').val() != null || $('#select_events').val() != null ||
            // Pending Fees selected
            $("input[name=pending_fees]").prop('checked') ||
            // Filter by Column's text selected
            $('input.column_filter[type="text"]').filter(function() {return this.value.length != 0}).length != 0
           )
        {
            $('#status_message').text(__('Existem filtros aplicados'));
        }
        else {
            $('#status_message').text('');
        }
    }

    pub.filterColumn = function(i) {
        dtTable.column( i ).search(
            $('#col'+i+'_filter').val(),
            $('#col'+i+'_regex').prop('checked'),
            !$('#col'+i+'_regex').prop('checked')
        ).draw();
    }

    pub.updateColumnVisibility = function(i) {
        var column = dtTable.column( i );
        column.visible( $('#col'+i+'_visible').prop('checked') );
        if (typeof Storage !== "undefined") {
            localStorage.setItem(tableName + $('#col'+i+'_visible').attr("id"), $('#col'+i+'_visible').prop("checked").toString());
        }
    }

    pub.updateColumnVisibilityCheckboxes = function(i) {
        var column = dtTable.column( i );
        $('#col'+i+'_visible').prop('checked', column.visible());
    }

    pub.loadSelectPickerFromStorage = function(pickerId) {
        return; // Disable this for now as it raises more problems than it solves ...
        $('#'+pickerId).selectpicker('val', JSON.parse(localStorage.getItem(tableName + $('#'+pickerId).attr('id')) ) );
        if (JSON.parse( localStorage.getItem(tableName + $('#'+pickerId).attr('id')) ))
            $('#status_message').text('Existem filtros aplicados');
    }

    pub.initDatatablesFilters = function(table) {
        // Remember table
        dtTable = table;

        // Build a unique name to use in storage
        var tableId = table.table().node().id;
        var lastIndex = tableId.indexOf('_');
        if (lastIndex != -1) {
            tableName = tableId.substring(0, lastIndex);
        }
        else {
            tableName = tableId;
        }
        tableName += '_';

        // Restore filter values from localStorage
        $('input.column_filter[type="text"]').each(function( index ) {
            if (typeof Storage !== "undefined") {
                $(this).val(localStorage.getItem(tableName + $(this).attr("id")));
            }
        });

        $('input.column_filter[type="checkbox"]').each(function( index ) {
            if (typeof Storage !== "undefined") {
                $(this).prop("checked", localStorage.getItem(tableName + $(this).attr("id")) == 'true');
            }
        });

        $('input.column_visible[type="checkbox"]').each(function( index ) {
            if (typeof Storage !== "undefined") {
                $(this).prop("checked", localStorage.getItem(tableName + $(this).attr("id")) != 'false');
            }
        });

        if ($('#pending_fees').length) {
            if (typeof Storage !== "undefined") {
                $("input[name=pending_fees]").prop("checked", localStorage.getItem(tableName + $("input[name=pending_fees]").attr("id")) == 'true');
                if (localStorage.getItem(tableName + $("input[name=pending_fees]").attr("id")) == 'true') {
                    $("#event_filters").addClass("disabled_filters");
                }
            }
        }

        // Update filter status in UI
        updateFiltersStatus();

        // Store filter values in Storage if they change and update status message.
        $('#select_events, #select_fees').on('hidden.bs.select', function () {
            if (typeof Storage !== "undefined") {
                localStorage.setItem(tableName + this.id, JSON.stringify($(this).val()));
            }
            updateFiltersStatus();
        });

        $('input.column_filter[type="text"]').on('focusout', function () {
            if (typeof Storage !== "undefined") {
                localStorage.setItem(tableName + $(this).attr("id"), $(this).val());
            }
            pub.filterColumn($(this).parents('tr').attr('data-column'));
            updateFiltersStatus();
        } );

        $('input.column_filter[type="checkbox"]').on('change', function () {
            if (typeof Storage !== "undefined") {
                localStorage.setItem(tableName + $(this).attr("id"), $(this).prop("checked").toString());
            }
            pub.filterColumn($(this).parents('tr').attr('data-column'));
        } );

        if ($('#pending_fees').length) {
            $("input[name=pending_fees]").on('change', function () {
                if (typeof Storage !== "undefined") {
                    localStorage.setItem(tableName + $(this).attr("id"), $(this).prop("checked").toString());
                }
                updateFiltersStatus();
            } );
        }

        // Define column's visibility update function
        $('input.column_visible').on( 'click', function () {
            pub.updateColumnVisibility( $(this).parents('tr').attr('data-column') );
        } );

        // We can't collide with the 'responsive' property, so we update visible
        // checkboxes to reflect visible columns, instead of trying to fight it.
        $('input.column_visible').each( function () {
            try {
                pub.updateColumnVisibility( $(this).parents('tr').attr('data-column') );
            }
            catch (e) {
                // During initialization updateColumnVisibility() generates an exception. TODO: Try to understand this ...
            }
            pub.updateColumnVisibilityCheckboxes( $(this).parents('tr').attr('data-column') );
        } );

        // Make sure column search filters are applied after a refresh
        $('input.column_filter[type="text"]').focusout();
    }

    // Return the public part of this object
	return pub;

})(jQuery, window, document);