var AppiCredentials = (function() {
    "use strict";

    // Set defaults here
    var defaults = {
    };

    // Public properties
	var pub = {};

    // Private properties
    var memberTable = null,
        credential_types = [
                            {"name": 'Staff', "color": "#0000FF" },
                            {"name": 'Speaker', "color": "#006400" },
                            {"name": 'Helper', "color": "#00CC00" },
                            {"name": 'Exhibitor', "color": "#FF4500" },
                            {"name": 'Delegate', "color": "#8B0000" },
                            {"name": 'Guest', "color": "#008080" },
                            {"name":  APPIconfig['company_name'], "color": "#0000CD" },
                           ],
        event_id = null,
        dtTable = null,          // The datatables table.
        dtTableId = null,        // The html id of the payments table.
        cf_validator = null;     // The validator for the credentials form.


    /**
     *
     * @param event Submit event.
     */
    pub.CredentialsFormSubmitHandler = function(event) {
        var modal = $(this).closest('.modal');
        var form = $(this).closest('form');
        var formData;
        var aData;

        event.preventDefault();

        formData = new FormData(form[0]);

        if (cf_validator.form()) {
            if (memberTable.row('.selected').data()) {
                // Use the selected rows
                aData = memberTable.rows('.selected').data();
            }
            else {
                // Use all rows!
                aData = memberTable.rows( {search:'applied'} ).data();
            }

            // Iterate through all rows and append to formData
            for (var i = 0; i < aData.length; i++) {
                formData.append('ids[]', aData[i]['id']);
            }

            modal.find('.spinner').show();
            // Invoke mailer using Ajax
            $.ajax({
                type: "POST",
                url: "/events/" + event_id + "/credentials",
                data: formData,
                processData: false,
                contentType: false,
                xhrFields: {
                            responseType: 'blob'
                },
                success: function(data, textStatus, xhr) {
                            modal.find('.spinner').hide();
                            modal.modal('hide');

                            var filename = "";
                            var disposition = xhr.getResponseHeader("Content-Disposition");
                            if (disposition && (disposition.indexOf("attachment") !== -1) || disposition.indexOf("filename") !== -1) {
                                var filenameRegex = /filename[^;=\n]*=(([""]).*?\2|[^;\n]*)/;
                                var matches = filenameRegex.exec(disposition);
                                if (matches != null && matches[1])
                                    filename = matches[1].replace(/[""]/g, "");
                            }

                            var type = xhr.getResponseHeader("Content-Type");
                            var blob = new Blob([data], {type: type});

                            var URL = window.URL || window.webkitURL;
                            var downloadUrl = URL.createObjectURL(blob);

                            if (typeof window.navigator.msSaveBlob !== "undefined") {
                                // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed.
                                window.navigator.msSaveBlob(blob, filename);
                            }
                            else {
                                if (filename) {
                                    // Use HTML5 a[download] attribute to specify filename.
                                    var a = document.createElement("a");
                                    // Safari doesn"t support this yet.
                                    if (typeof a.download === "undefined") {
                                        window.location = downloadUrl;
                                    } else {
                                        a.href = downloadUrl;
                                        a.download = filename;
                                        document.body.appendChild(a);
                                        a.click();
                                    }
                                }
                                else {
                                    window.location = downloadUrl;
                                }

                                setTimeout(function () {
                                    URL.revokeObjectURL(downloadUrl);
                                }, 100); // Cleanup
                            }
                        },
                error: function(xhr, err, status) {
                            modal.find('.spinner').hide();
                            modal.modal('hide');
                        },
                complete: function(xhr, status) {

                        }
            });
        }

    }

    pub.OnShowCredentialsModal = function (event) {
        var modal = $(this).closest('.modal');
        var aData;
        const credential_counts = ['8', '6', '4'];

        modal.find('.spinner').hide();

        cf_validator.resetForm()

        // Reset all fields
        $(event.target).find('form')[0].reset();

        $('.colorpicker-component').colorpicker('setValue', '#000000');

        if ($("#credential_type").children().length == 0) {
            credential_types.forEach(function (option) {
                $("#credential_type").append('<option value=' + option.name + '>' + option.name + '</option>\n');
            });
            $('#credential_type').selectpicker('refresh');
        }
        $('#credential_type').val(''); $('#credential_type').selectpicker('deselectAll');

        if ($("#credentials_per_page").children().length == 0) {
            credential_counts.forEach(function (option) {
                $("#credentials_per_page").append('<option value=' + option + '>' + option + '</option>\n');
            });
        }
        $('#credentials_per_page').val(credential_counts[0]);
        $('#credentials_per_page').selectpicker('refresh');

        if (memberTable.row('.selected').data()) {
            // Use the selected rows
            aData = memberTable.rows('.selected').data();
        }
        else {
            // Use all rows! Dangerous!
            aData = memberTable.rows( {search:'applied'} ).data();
        }

        if (aData.length == 0) {
            AppiUtils.SetModalTitle(this, __('A lista de participantes está vazia'));
            $(this).find(':submit').prop('disabled', true);
        }
        else {
            if (aData.length == 1)
                AppiUtils.SetModalTitle(this, __('Vai ser gerada a credencial para 1 participante.'));
            else
                AppiUtils.SetModalTitle(this, __('Vão ser geradas credenciais para :nr participantes.', {"nr": aData.length }));
            $(this).find(':submit').prop('disabled', false);
        }
    }


    /**
     * Associates the specified table id with a datatables table.
     *
     * @param {string} id - The table id.
     */                  /* credentials_form */
    pub.InitForm = function(credentials_form_id, credentials_modal_id, member_table, eventId) {
        cf_validator = AppiUtils.initFormValidation(credentials_form_id);
        memberTable = member_table;
        event_id = eventId;

        // Populate modals before they are shown.
        $('#'+credentials_modal_id).on('show.bs.modal', this.OnShowCredentialsModal);

        // Submit handler
        $("form#" + credentials_form_id + " button#submit").click(this.CredentialsFormSubmitHandler);

        $('.colorpicker-component').colorpicker();

        $('#credential_type').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
            if (clickedIndex != undefined) {
                $('#credential_type_colorpicker').colorpicker('setValue', credential_types[clickedIndex].color);
            }
          });
    }

    // Return the public part of this object
	return pub;
}());