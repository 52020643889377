var AppiUtils = (function() {
	var pub = {};

	pub.JQueryValidateOptions = {
        ignore: [],
        highlight: function(element) {
            $(element).closest('.form-group').addClass('has-error');
        },
        unhighlight: function(element) {
            $(element).closest('.form-group').removeClass('has-error');
        },
        errorElement: 'span',
        errorClass: 'help-block',
        errorPlacement: function(error, element) {
            if (element.prop("type") === "checkbox" || element.prop("type") === "radio") {
                error.appendTo(element.parent().parent()); // was originaly as bellow
            }
            else if(element.parent('.input-group').length) {
                error.insertAfter(element.parent());
            } else {
                error.insertAfter(element);
            }
        },
        rules: {
            nif: { NIFValidation: true },
            zip_code: { ZipValidation: true }
        }
    };

    ///////////////////////////////////////////////////////////////////////////
    // Jquery Validade validators
    pub.IsValidNif = function(nif) {
        var country = $("#country").val();

        // An empty NIF is NOT an error
        if (!nif) return true;

        if (country == "Portugal" || country == undefined) {
            // #country doesn't exist or exists and it's value is 'Portugal'
            var sum;
            var number;
            var weight;

            if (nif.length == 0) { return true; }
            if (nif.length != 9) { return false; }
            for (number = Math.floor(nif/10), sum = 0, weight = 2; number; weight++)
            {
                sum += (number % 10) * weight;
                number = Math.floor(number/10);
            }
            return (sum % 11 ? 11 - sum % 11 : 0) % 10 == nif % 10;
        }
        else if (country == "Espanha" || country == "Spain") {
            // https://rosaallegue.com/2018/06/29/cif-tax-identification-number-for-legal-entities-in-spain-how-to-calculate-digit-control-and-to-validate/
            var check_letter = ["T", "R", "W", "A", "G", "M", "Y", "F", "P", "D", "X", "B", "N", "J", "Z", "S", "Q", "V", "H", "L", "C", "K", "E"];
            var regex = RegExp('[0-9A-Z][0-9]{7}[0-9A-Z]');
            nif = nif.toUpperCase();

            if (!regex.test(nif)) return false;

            if (nif.substr(0, 1) == 'X') {
                nif = '0' + nif.substr(1);
            }
            else if (nif.substr(0, 1) == 'Y') {
                nif = '1' + nif.substr(1);
            }
            else if (nif.substr(0, 1) == 'Z') {
                nif = '2' + nif.substr(1);
            }
            else if (!Number.isInteger(nif.substr(0, 1))) {
                //nif = nif.substr(1);
                var a = parseInt(nif.substr(2, 1)) + parseInt(nif.substr(4, 1)) + parseInt(nif.substr(6, 1));
                var b = parseInt(Math.floor(nif.substr(1, 1)) * 2 / 10) + parseInt(nif.substr(1, 1)) * 2 % 10 +
                        parseInt(Math.floor(nif.substr(3, 1)) * 2 / 10) + parseInt(nif.substr(3, 1)) * 2 % 10 +
                        parseInt(Math.floor(nif.substr(5, 1)) * 2 / 10) + parseInt(nif.substr(5, 1)) * 2 % 10 +
                        parseInt(Math.floor(nif.substr(7, 1)) * 2 / 10) + parseInt(nif.substr(7, 1)) * 2 % 10
                var c = a + b;
                var d = 10 - c % 10;
                if (nif.substr(0, 1) == 'A') {
                    return d.toString() == nif.substr(8, 1) || String.fromCharCode(64 + d) == nif.substr(8, 1);
                }
                else {
                    return d.toString() == nif.substr(8, 1) || String.fromCharCode(64 + d) == nif.substr(8, 1);
                }
            }

            return check_letter[parseInt(nif.substr(0, 8)) % 23] == nif.substr(8, 1);
        }
        else {
            return true;
        }
   	}

    pub.IsValidZip = function(zip) {
        var country = $("#country").val();

        // An empty ZIP is NOT an error
        if (!zip) return true;

        if (country == "Portugal" || country == undefined) {
            // #country doesn't exist or exists and it's value is 'Portugal'
            var regex = RegExp('^[0-9]{4}-[0-9]{3}$');
            return regex.test(zip);
        }
        else if (country == "Espanha" || country == "Spain") {
            var regex = RegExp('^(?:0[1-9]|[1-4]\\d|5[0-2])\\d{3}');
            return regex.test(zip);
        }
        else if (country == "England" | country == "Inglaterra") {
            var regex = RegExp('([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\\s?[0-9][A-Za-z]{2})');
            return regex.test(zip);
        }
        else {
            return true;
        }
    }

    ///////////////////////////////////////////////////////////////////////////
    // Date Functions

    /**
     * Converts a string in the form 'yyyy-mm-dd' to a date.
     *
     * @param  {string|null} dateStr The date string or an empty string.
     * @return {Date|null} The corresponding Date or null if the string is empty.
     */
    pub.toDate = function(dateStr) {
        if (!dateStr) return null;
        var parts = dateStr.split("/");
        if (parts[0].length == 4) {
            // String was 'yyyy/mm/dd'
            return new Date(parts[0], parts[1] - 1, parts[2]);
        }
        else {
            // String was 'dd/mm/yyyy'
            return new Date(parts[2], parts[1] - 1, parts[0]);
        }
    }

    ///////////////////////////////////////////////////////////////////////////
    // Modal Functions
    pub.SetModalTitle = function(modal, title) {
      $(modal).find('.modal-title').text(title);
    }

    ///////////////////////////////////////////////////////////////////////////
    /// Validations
    /**
     * Prepare custom validations: pattern and NifValidation.
     * And initialize JQuery Validate.
     */
    pub.initFormValidation = function(formId) {

        /**
         *  RegEx validation. Returns true if RegEx matches.
         *
         *  Usage:
         *  1. In a text input element, specify a RegEx using pattern="..."
         *  2. In the same input element use data-error="..." to specify the error
         *     that will be shown in case the specified pattern doesn't match.
         *  3. In case data-error="..." is not specified, the default error message -
         *     "Formato inválido" - will be shown.
         */
        jQuery.validator.addMethod("pattern", function(value, element, param) {
            if (this.optional(element)) {
                return true;
            }
            if (typeof param === "string") {
                param = new RegExp("^(?:" + param + ")$");
            }
            return param.test(value);
        }, function(value, element, param) {
                var el;
                if (this.currentElements.length == 1)
                    el = $(this.currentElements[0]);
                else {
                    for (var i = 0; i < this.currentElements.length; i++) {
                        if (this.currentElements[i].id === element.id) {
                            el = $(this.currentElements[i]);
                            break;
                        }
                    }
                }
                return el && el.data('error') ? el.data('error') : "Formato inválido";
            });

        /**
         * NIF validation. Returns true if the specified number is a valid NIF.
         *
         *  Usage:
         *  1. Configure the 'rules' JQuery Validation using the id of an input field
         *     where a NIF will be entered. For example, for an input with id="nif":
         *
         *      rules: {
         *          nif: {
         *              NIFValidation: true
         *          }
         *      }
         *
         *  2. In the same input element use data-error="..." to specify the error
         *     that will be shown in case the specified pattern doesn't match.
         *  3. In case data-error="..." is not specified, the default error message -
         *     "NIF inválido" - will be shown.
         */
        jQuery.validator.addMethod('NIFValidation', pub.IsValidNif,
            function(value, element, param) {
                var el;
                if (this.currentElements.length == 1)
                    el = $(this.currentElements[0]);
                else {
                    for (var i = 0; i < this.currentElements.length; i++) {
                        if (this.currentElements[i].id === element.id) {
                            el = $(this.currentElements[i]);
                            break;
                        }
                    }
                }
                return el && el.data('error') ? el.data('error') : "NIF inválido";
            });

        jQuery.validator.addMethod('ZipValidation', pub.IsValidZip,
            function(value, element, param) {
                var el;
                if (this.currentElements.length == 1)
                    el = $(this.currentElements[0]);
                else {
                    for (var i = 0; i < this.currentElements.length; i++) {
                        if (this.currentElements[i].id === element.id) {
                            el = $(this.currentElements[i]);
                            break;
                        }
                    }
                }
                return el && el.data('error') ? el.data('error') : "C.P. inválido";
            });

        // Initialize JQuery Validate and keep validator in a public variable
        var validator = $('#'+formId).validate(pub.JQueryValidateOptions);

        return validator;
    }

    ///////////////////////////////////////////////////////////////////////////
    /// HTML Form functions

    pub.addHiddenElementToForm = function(form_id, element_id, value) {
        if ( $('#'+element_id).length == 0) {
            $('<input>', {type: 'hidden', id: element_id, name: element_id, value: value}).appendTo('#'+form_id);
        }
        else {
            $('#'+element_id).val(value);
        }
    }

    ///////////////////////////////////////////////////////////////////////////
    // EML file forms

    /**
     *  Picks a member's fields from an application form sent by email.
     *
     * @param  {string} Text where field should be searched.
     * @param  {string} The beginning tag of the field.
     * @param  {string} RegEx to match the field.
     * @param  {string} Final tag of the field. If specified everything between iniTag and finalTag will be
     *                  matched.
     * @param  {bool}   If finalTag is specified, indicates if '\n' should be kept.
     * @return {string} The matched text.
     */
    pub.getFormFieldText = function(text, iniTag, matcher, finalTag, multipleLines, rawIniTag) {
        var array;
        var re = new RegExp((iniTag ? iniTag : '') + (rawIniTag ? '' : '[\\s]*') +
                            (finalTag ? "((?:(?!" + finalTag + ")[^])*)" : (matcher ? matcher : '(.+)')), "i");
        array = text.match(re);
        if (finalTag)
            if (multipleLines)
                return array[1].trim();
            else
                return array[1].replace(/\r\n/g, ' ').trim();
        else if (array && array[1])
            return array[1].trim();
        else
            return '';
    }

    /**
     * Parses an email, in EML format, with a member application form.
     * @param  {string} text The text read from he EML file.
     * @return {Object}      A form_data object (see properties above)
     */
    pub.parseMemberApplicationFormEML = function(text) {
        var form_data = {
            type:           'UNKNOWN',  // One of FEE, CONFERENCE, ...
            full_name:      '',
            last_name:      '',
            birth_date:     '',         // DD/MM/YYYY
            nif:            '',         //
            raw_address:    '',
            address:        '',
            address_line_0: '',
            address_line_1: '',
            country:        '',
            zip:            '',
            place:          '',
            district:       '',
            phone:          '',         //
            email:          '',         //
            edu_institution:'',
            edu_level:      '',
            raw_text:       ''         // Raw text read from the email
        };
        var addressLines;

        // We're only concerned with the first text/plain section
        var qp_start_index = text.search('Content-Type: text/plain;[ \n\r\t]*charset="UTF-8"[ \n\r\t]*Content-Transfer-Encoding: quoted-printable');
        if (qp_start_index != -1) {
            var qp_end_index = qp_start_index + text.substring(qp_start_index).search('_NextPart_');
            var begin = text.substring(0, qp_start_index);
            var end = text.substring(qp_end_index);
            form_data['raw_text'] = this.decode_utf8( quotedPrintable.decode( text.substring(qp_start_index, qp_end_index) ) );
            text = begin + form_data['raw_text'] + end;
        }
        else if ((qp_start_index = text.search('Content-Type: text/plain[ \n\r\t]*')) != -1) {
            var qp_end_index = qp_start_index + text.substring(qp_start_index).search('_NextPart_');
            form_data['raw_text'] = text.substring(qp_start_index, qp_end_index);
        }

        if (text.match(/Member\ application/i) === null) {
            return form_data;
        }

        form_data['type'] = 'MEMBER';

        // Get full and last names
        form_data['last_name'] = this.getFormFieldText(text, 'apelido[^:]*:', '(.+)', '');
        form_data['full_name'] = this.getFormFieldText(text, 'Nome\ completo[^:]*:', '(.+)', '');

        // There are many cases where the full_name doesn't include the last_name.
        if (form_data['full_name'].indexOf(form_data['last_name']) == -1) {
            form_data['full_name'] = form_data['full_name'] + ' ' + form_data['last_name'];
        }

        // TODO - Make sure we have the right separators
        form_data['birth_date'] = this.getFormFieldText(text, 'Data\ de\ Nascimento[^:]*:', '([0-9]{1,2}[^0-9][0-9]{1,2}[^0-9][0-9]{4})', '');

        form_data['nif'] = this.getFormFieldText(text, 'Número\ de\ Contribuinte[^:]*:', '([0-9]{9})', '');

        form_data['admission_year'] = new Date().getFullYear();

        form_data['address'] = this.getFormFieldText(text, 'Morada[^:]*:', '', 'Telefone[^:]*:', true);
        form_data['raw_address'] = form_data['address'];

        addressLines = form_data['address'].match(/[^\r\n]+/g);
        form_data['address'] = '';
        addressLines.forEach(function(entry) {
            form_data['address'] = form_data['address'] + '\n' + entry;
        });

        form_data['country'] = addressLines[addressLines.length - 1];

        form_data['address'] = addressLines[0] ? addressLines[0].trim() : '';

        var repeat;
        do {
          repeat = false;
          addressLines[1] = addressLines[1].replace(addressLines[0], '');
          if (addressLines[1].startsWith(',')) {
              addressLines[1] = addressLines[1].substring(1).trim();
          }

          if (addressLines.length > 3) {
            if (addressLines[0].indexOf(addressLines[1]) === -1) {
                addressLines[0] = addressLines[0].trim() + ' ' + addressLines[1].trim();
                form_data['address'] = addressLines[0];
            }
            addressLines.splice(1, 1);
            repeat = true;
          }
        } while (repeat);

        // Try to match a 7-digit zip code.
        var zip = addressLines[1].match('[0-9]{4}[^0-9]+[0-9]{3}');
        if (zip === null) {
            // This is a 4-digit CP
            zip = addressLines[1].match('[0-9]{4}');
        }

        // The zip code can appear multiple times in the address.
        addressLines[0] = addressLines[0].replace(zip, '');
        addressLines[1] = addressLines[1].replace(zip, '');

        form_data['address'] = addressLines[0] ? addressLines[0].trim() : '';

        form_data['address_line_0'] = addressLines[0];
        form_data['address_line_1'] = addressLines[1];

        // There can be any non-digit chars between the two groups.
        // Replace them all with a dash.
        zip[0] = zip[0].replace(/[^0-9]+/, '-');

        // Update zip code.
        form_data['zip'] = zip[0];

        // Place will temporarily have the rest of the address
        form_data['place'] = addressLines[1];

        // Clean the address of any spaces and commas in the end of the line.
        form_data['address'] = form_data['address'].replace(/[\n\s,]+$/, "");

        var telefone = this.getFormFieldText(text, 'Telefone[^:]*:', '(.+)', '');
        if (telefone) {
            form_data['phone'] = telefone.trim();
        }

        form_data['email'] = this.getFormFieldText(text, 'Email[^:]*:', '([^<\n\r]+)', '');

        form_data['edu_institution'] = this.getFormFieldText(text, 'Estabelecimento\ de\ ensino[^:]*:', '', 'Nível\ de\ ensino');
        form_data['edu_level'] = this.getFormFieldText(text, 'Nível\ de\ ensino[^:]*:', '(.+)', '');

        return form_data;
    };

    /**
     * Parses an email, in EML format, with a payment form.
     * @param  {string} fileText The tesx read from he EML file.
     * @return {Object}          A form_data object (see properties above)
     */
    pub.parsePaymentFormEML = function(fileText) {
        var form_data = {
            type:           'UNKNOWN',  // One of FEE, CONFERENCE, ...
            email_date:     '',         // DD/MM/YYYY
            email_subject:  '',         //
            fee_year:       '',         // YYYY
            payment_amount: '',         // 00.00
            payment_method: '',         // Transferência bancária|Cheque|Vale Postal
            payment_proof:  '',         // An URL to an image file uploaded to APPI
            member_name:    '',         //
            member_number:  '',         // ([AB] XXXX) if member, null if not

            address:        '',
            raw_address:    '',
            address_line_0: '',         // Not used
            address_line_1: '',         // Not used
            zip:            '',
            place:          '',
            district:       '',
            country:        '',
            edu_institution:'',
            edu_level:      '',
            birth_date:     '',

            nif:            '',         //
            ncc:            '',         // ID card number
            phone:          '',         //
            email:          '',         //
            raw_text:       ''          // Raw text read from the email
        };

        // We're only concerned with the first text/plain section
        var qp_start_index = fileText.search('Content-Type: text/plain;[ \n\r\t]*charset="UTF-8"[ \n\r\t]*Content-Transfer-Encoding: quoted-printable');
        if (qp_start_index != -1) {
            var section_header_end_index = fileText.substring(qp_start_index).indexOf('\r\n\r\n');
            qp_start_index += section_header_end_index + '\r\n\r\n'.length;
            var qp_end_index = qp_start_index + fileText.substring(qp_start_index).search('_NextPart_');
            var begin = fileText.substring(0, qp_start_index);
            var end = fileText.substring(qp_end_index);
            form_data['raw_text'] = this.decode_utf8( quotedPrintable.decode( fileText.substring(qp_start_index, qp_end_index) ) );
            fileText = begin + form_data['raw_text'] + end;
        }
        else if ((qp_start_index = fileText.search('Content-Type: text/plain[ \n\r\t]*')) != -1) {
            var qp_end_index = qp_start_index + fileText.substring(qp_start_index).search('_NextPart_');
            form_data['raw_text'] = fileText.substring(qp_start_index, qp_end_index);
        }

        // Check known form formats inside email
        if (fileText.match(/Sócios\ -\ Pagamento de Quota/i)) {
            form_data['type'] = 'FEE';
            form_data['fee_year'] = this.getFormFieldText(fileText, 'Valor\ das\ quotas[^\:]*[:]+', '([0-9]+)', '');
            form_data['payment_method'] = this.getFormFieldText(fileText, 'Meios\ de\ pagamento[^\:]*:', '(Transferência bancária|Cheque|Vale Postal)', '');
            form_data['payment_amount'] = this.getFormFieldText(fileText, 'Valor\ das\ quotas[^\:]*[:]+', '[0-9]*[\ \-]*([0-9\.]+)', '');
            form_data['payment_amount'] = Number(form_data['payment_amount']).toFixed(2);

            form_data['edu_institution'] = this.getFormFieldText(fileText, 'Estabelecimento\ de\ ensino[^:]*:', '([^]*)Nível de Ensino[^:]*:', '');
            form_data['edu_level'] = this.getFormFieldText(fileText, 'Nível\ de\ ensino[^:]*:', '[\ ]*([^\r|]*)', '');

            form_data['address'] = this.getFormFieldText(fileText, 'Morada[^:]*:', '', 'Telefone[^:]*:', true);
        }
        else if (fileText.match(/Conference\ Registration\ Form/i)) {
            form_data['type'] = 'CONFERENCE';
            form_data['payment_method'] = this.getFormFieldText(fileText, 'Meios\ de\ pagamento[^\:]*:', '(Transferência bancária|Cheque|Vale Postal)', '');
            form_data['payment_amount'] = this.getFormFieldText(fileText, 'Inscrições[^\:]*:', '[^]*?(€[0-9]+|[0-9]+€)', '');
            form_data['payment_amount'] = Number(AppiUtils.trim(form_data['payment_amount'], '€')).toFixed(2);

            form_data['edu_institution'] = this.getFormFieldText(fileText, 'Estabelecimento\ de\ ensino[^:]*:', '([^]*)Nível de Ensino[^:]*:', '');
            form_data['edu_level'] = this.getFormFieldText(fileText, 'Nível\ de\ ensino[^:]*:', '[\ ]*([^\r|]*)', '');

            form_data['address'] = this.getFormFieldText(fileText, 'Morada[^:]*:', '', 'Telefone[^:]*:', true);
        }
        else if (fileText.match(/Seminar\ Registration\ Form/i)) {
            form_data['type'] = 'CONFERENCE';
            form_data['payment_method'] = this.getFormFieldText(fileText, 'Método\ de\ pagamento[^\:]*:', '(Transferência bancária|Cheque|Vale Postal)', '');
            form_data['payment_amount'] = this.getFormFieldText(fileText, 'Inscrições[^\:]*:', '[^]*?(€[0-9]+|[0-9]+€)', '');
            form_data['payment_amount'] = Number(AppiUtils.trim(form_data['payment_amount'], '€')).toFixed(2);

            form_data['edu_institution'] = this.getFormFieldText(fileText, 'Estabelecimento\ de\ ensino[^:]*:', '([^]*)Nível de Ensino[^:]*:', '');
            form_data['edu_level'] = this.getFormFieldText(fileText, 'Nível\ de\ ensino[^:]*:', '[\ ]*([^\r|]*)', '');

            form_data['address'] = this.getFormFieldText(fileText, 'Morada[^:]*:', '', 'Telefone[^:]*:', true);
            form_data['ncc'] = this.getFormFieldText(fileText, 'Cartão Cidadão[^:]*:', '[0-9]+', '');
        }
        else if (fileText.match(/APPInep/i)) {
            form_data['type'] = 'CONFERENCE';
            form_data['payment_method'] = this.getFormFieldText(fileText, 'Meios\ de\ pagamento[^\:]*:', '(Transferência bancária|Cheque|Vale Postal)', '');
            form_data['payment_amount'] = this.getFormFieldText(fileText, 'Inscrições[^\:]*:', '[^0-9]+([0-9\.]+)', '');
            form_data['payment_amount'] = Number(form_data['payment_amount']).toFixed(2);

            form_data['edu_institution'] = this.getFormFieldText(fileText, 'Estabelecimento\ de\ ensino[^:]*:', '([^]*)Inscrições[^:]*:', '');
            form_data['edu_level'] = this.getFormFieldText(fileText, 'Nível\ de\ ensino[^:]*:', '[\ ]*([^\r|]*)', '');

            form_data['address'] = this.getFormFieldText(fileText, 'Morada[^:]*:', '', 'Número\ de\ Telefone[^:]*:', true);
        }
        else if (fileText.match(/APPIForma/i)) {
            form_data['type'] = 'APPIFORMA';
            form_data['raw_text'] = form_data['raw_text'].substring(form_data['raw_text'].search(/Ficha de Inscrição/i));
            form_data['raw_text'] = form_data['raw_text'].replace(/\r\n(?!\r)/g, '');
            form_data['payment_method'] = this.getFormFieldText(fileText, 'Formas\ de\ pagamento[^\:]*:', '(Transferência bancária|Cheque|Vale Postal)', '');
            form_data['payment_amount'] = this.getFormFieldText(fileText, 'Valores[^\:]*:', '[^0-9]*([0-9\.]+)[^0-9]*\r', '');
            form_data['payment_amount'] = Number(form_data['payment_amount']).toFixed(2);

            form_data['edu_institution'] = this.getFormFieldText(fileText, 'Estabelecimento\ de\ ensino[^:]*:', '', 'Grau de ensino[^:]*:');
            form_data['edu_level'] = this.getFormFieldText(fileText, 'Grau\ de\ ensino[^:]*:', '[\ ]*([^\r|]*)', '');

            form_data['birth_date'] = this.getFormFieldText(fileText, 'Data\ de\ Nascimento[^:]*:', '([0-9]{1,2}[^0-9]+[0-9]{1,2}[^0-9]+[0-9]{4})', '');

            form_data['address'] = this.getFormFieldText(fileText, 'Morada[^:]*:', '', 'Telefone[^:]*:', true);
        }
        else {
            // Unknown file type
            return form_data;
        }

        // Read address keeping lines
        form_data['raw_address'] = form_data['address'];
        if (form_data['address'].split('\n').length > 1) {
            form_data['country'] = form_data['address'].substring(form_data['address'].lastIndexOf("\n") + 1);
        }
        else {
            if (form_data['address'].lastIndexOf("\r") != -1)
                form_data['address'] = form_data['address'].substring(0, form_data['address'].lastIndexOf("\r"))
        }

        // Remove line endings
        form_data['address'] = form_data['address'].replace(/\r\n/g, ' ').trim();

        form_data['zip'] = this.getFormFieldText(form_data['address'], '', '([0-9]{4}[^0-9]+[0-9]{3})', '');
        if (!form_data['zip']) {
            // Try to match a 4 digit zip code.
            form_data['zip'] = this.getFormFieldText(form_data['address'], '', '([0-9]{4})', '');
        }
        else {
            // Replace any non digits with an hifen
            form_data['zip'] = form_data['zip'].replace(/[^0-9]+/, '-');
        }
        form_data['address'] = form_data['address'].replace(form_data['country'], '').trim();
        form_data['address'] = form_data['address'].replace(form_data['zip'], '').trim();
        if (form_data['zip']) {
            var last_space_index =  form_data['address'].lastIndexOf(' ');
            var last_but_one_space_index =  form_data['address'].substring(0, last_space_index).lastIndexOf(' ');
            var last_but_one_word = form_data['address'].substring(last_but_one_space_index + 1, last_space_index);

            var words = ['de', 'da', 'das', 'do', 'dos'];
            if ($.inArray(last_but_one_word, words) > -1)
                form_data['place'] = form_data['address'].substring(form_data['address'].substring(0, last_but_one_space_index).lastIndexOf(' ') + 1);
            else
                form_data['place'] = form_data['address'].substring(form_data['address'].lastIndexOf(' ') + 1);
        }
        form_data['address'] = form_data['address'].replace(form_data['place'], '').trim();
        form_data['address'] = this.rtrim(form_data['address'], ',');
        form_data['address_line_1'] = form_data['address'];

        // Erase any garbage from zip code here, after cleaning address.
        form_data['zip'] = form_data['zip'].replace(/ /g, '');

        /////////////////////////////////////////////////////////////////////
        // Parse common fields

        // Email date
        form_data['email_date'] = this.getFormFieldText(fileText, 'Date[^\:]*:', '[A-Z][a-z]{2}, ([0-9]* [a-zA-Z]* [0-9]*)', '');
        var parts = form_data['email_date'].split(' ');
        form_data['email_date'] = parts[0]+'/'+this.monthNumberFromShortName(parts[1])+'/'+parts[2];

        // Email subject - Can be quoted-printable in multiple lines
        form_data['email_subject'] = this.getFormFieldText(fileText, 'Subject[^:]*:', '', 'Date[^:]*:', true);
        var start = form_data['email_subject'].indexOf('=?');
        if (start != -1) {
            // Quoted-printable encoded
            start += 2;
            var end = form_data['email_subject'].lastIndexOf('?=');
            form_data['email_subject'] = form_data['email_subject'].substring(start, end).replace(/\?=[\n\r\t]+=\?/g, '');
            form_data['email_subject'] = form_data['email_subject'].replace(/UTF-8\?Q\?/g, '');
            form_data['email_subject'] = this.decode_utf8(quotedPrintable.decode(form_data['email_subject']));
            form_data['email_subject'] = form_data['email_subject'].replace(/_/g, ' ');
            end = form_data['email_subject'].indexOf('|');
            if (end != -1) {
                form_data['email_subject'] = form_data['email_subject'].substring(0, end).trim();
            }
        }
        else {
            // Straigth text
            var end = form_data['email_subject'].search(/[|\r]/i);
            if (end != -1) {
                form_data['email_subject'] = form_data['email_subject'].substring(0, end);
            }
        }

        // Payer name
        form_data['member_name'] = this.getFormFieldText(fileText, 'Nome\ Completo[^\:]*:', '([^\r]+)', '');
        var last_name = this.getFormFieldText(fileText, 'Apelido[^\:]*:', '([^\r]+)', '');
        if (form_data['member_name'].indexOf(last_name) == -1) {
            form_data['member_name'] = form_data['member_name'] + ' ' + last_name;
        }

        // Member number
        form_data['member_number'] = this.getFormFieldText(fileText, 'Sócio nº[^\:]*[:]*', '([AB]{0,1}[^0-9]{0,3}[0-9]+)', '');
        if (form_data['member_number'] &&
            form_data['member_number'].match(/^[AB]/i)) {
                if ("0123456789".indexOf(form_data['member_number'].substr(1, 1)) != -1) {
                    form_data['member_number'] = form_data['member_number'].slice(0, 1) + ' ' +
                                                 this.zfill(form_data['member_number'].slice(1), 4);
                }
                else {
                    var parts = form_data['member_number'].split(/[^AB0-9]/);
                    form_data['member_number'] = parts[0] + ' ' + this.zfill(parts[parts.length-1], 4);
                }
        }

        // NIF, phone and email
        form_data['nif'] = this.getFormFieldText(fileText, 'Número\ de\ Contribuinte[^\:]*:', '([0-9]{9})', '');
        form_data['phone'] = this.getFormFieldText(fileText, 'Telefone[^\:]*:', '([0-9]*)[\ \r]*', '');
        form_data['email'] = this.getFormFieldText(fileText, 'Email[^\:]*:', '([^\ \r]+)', '');

        // Attachement URL
        form_data['payment_proof'] = this.getFormFieldText(fileText, 'Comprovativo de pagamento[^\:]*[:]+', '([^\ <]*)[\ <]');
        // Check attachment empty lines
        if (form_data['payment_proof'].startsWith('This')) {
            form_data['payment_proof'] = '';
        }

        return form_data;
    };

    //////////////////////////////////////////////////////////////////////////
    /// Verify zip code using server

    /**
     * Check zip code using our own server using AJAX.
     * This is asynchronous, so, the specified html input id will be updated
     * when the response comes.
     *
     * @note Assumes form contains elements with ids: '#place', '#district'
     *       and '#address'
     *
     * @param form_data The information we have about the form contents
     * @param address_el_id The html id of the element holding the address.
     */
    pub.ajaxCheckZipCode = function(form_data, address_el_id) {
            var that = this;
            if (form_data['zip'].length) {
                // AJAX our own server about the specified CP.
                $.ajax({
                  type: "GET",
                  url: "/cp" + '/' + form_data['zip']
                }).done(function( html ) {
                    var response = JSON.parse(html);
                    if (form_data['zip'].length == 8 || (form_data['zip'].length == 4 && !$('#place').val()) )
                        $('#place').val(that.titleCase(response['local']));
                    $('#district').val(that.titleCase(response['distrito']));

                    if (form_data['address_line_0'] || form_data['address_line_1']) {
                        var idx1 = form_data['address_line_1'].toLowerCase().lastIndexOf(response['local'].toLowerCase());
                        var idx2 = form_data['address_line_1'].toLowerCase().lastIndexOf(response['distrito'].toLowerCase());
                        var haveSomething = true;

                        if (idx1 == -1 && idx2 != -1) {
                            form_data['address_line_1'] = form_data['address_line_1'].substring(0, idx2);
                        }
                        else if (idx1 != -1 && idx2 == -1) {
                            form_data['address_line_1'] = form_data['address_line_1'].substring(0, idx1);
                        }
                        else if ( Math.min(idx1, idx2) != -1) {
                            form_data['address_line_1'] = form_data['address_line_1'].substring(0, Math.min(idx1, idx2));
                        }
                        else {
                            haveSomething = false;
                        }
                        if (haveSomething) {
                            // Sometimes there is something more than the zip code in the last address line.
                            form_data['address'] = form_data['address_line_0'].trim() + ' ' +
                                                form_data['address_line_1'].trim();
                        }

                        // We can't strip the 'local' from the address as there are cases where that
                        // name is legit.
                        //$('#address').val($('#address').val().replace(new RegExp(response['local'], "i"), ""));

                        // Clean the address of any spaces and commas in the end of the line.
                        $('#address').val(form_data['address'].replace(/[\n\s,]+$/, ""));
                    }
                }).fail(function() {
                    // There was an error getting CP information.
                });
            }
    }

    /// Verify zip code using server
    //////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////
    /// Generic functions

    pub.monthNumberFromShortName = function (monthShortName) {
        return ["jan", "feb", "mar", "apr",
                "may", "jun", "jul", "aug",
                "sep", "oct", "nov", "dec"].indexOf(monthShortName.toLowerCase()) + 1;
    }

    pub.monthNumberFromName = function (monthName) {
        return ["january", "february", "march", "april",
                "may", "june", "july", "august",
                "september", "october", "november", "december"].indexOf(monthName.toLowerCase()) + 1;
    }

    pub.zfill = function (number, size) {
      number = number.toString();
      while (number.length < size) number = "0" + number;
      return number;
    }

    pub.encode_utf8 = function (s) {
      return unescape(encodeURIComponent(s));
    }

    pub.decode_utf8 = function (s) {
      return decodeURIComponent(escape(s));
    }

    /**
     * Checks two names for equality.
     * The names will be considered equal if one includes all the words
     * present in the other, in the correct order.
     *
     * @param name1 One name
     * @param name2 Other name
     * @returns true if names match, false otherwise.
     */
    pub.isSameName = function(name1, name2) {
        var patt;
        if (name1 && name2) {
            if (name1.length > name2.length) {
                patt = new RegExp(name2.replace(/ /g, '[^]*'));
                return patt.test(name1);
            }
            else {
                patt = new RegExp(name1.replace(/ /g, '[^]*'));
                return patt.test(name2);
            }
            //return name1.indexOf(name2) != -1 || name2.indexOf(name1) != -1;
        }
        else {
            return false;
        }
    }

    pub.formatMemberNumber = function (number) {
        const WIDTH = 0;
        number = number.trim();
        var split_index = number.search(/[^A-Z0-9]/);
        if (split_index != -1)
            number = number.replace(number.substr(split_index, 1), ' ');
        var parts = number.split(' ');
        if (parts.length == 1) {
            if ("1234567890".indexOf(parts[0].substring(0, 1)) == -1) {
                // Starts with a letter
                var first_digit_index = number.search(/\d/);
                number = number.substring(0, first_digit_index) + ' ' +
                         this.zfill(number.substring(first_digit_index), WIDTH);
            }
            else {
                // Starts with a digit
                number = this.zfill(parts[0], WIDTH);
            }
        }
        else {
            number = parts[0] + ' ' + this.zfill(parts[parts.length - 1], WIDTH);
        }
        return number;
    }

    pub.isSameNumber = function(number1, number2) {
        if (number1 && number2) {
            number1 = this.formatMemberNumber(number1);
            number2 = this.formatMemberNumber(number2);
            if (number1.length > number2.length) {
                return number1.endsWith(number2);
            }
            else {
                return number2.endsWith(number1);
            }
        }
        else {
            return false;
        }
    }

    /////////////////////////////////////////////////////////////////////////
    // String utils

    function makeString(object) {
      if (object == null) return '';
      return String(object);
    };

    function escapeRegExp(str) {
      return makeString(str).replace(/([.*+?^=!:${}()|[\]\/\\])/g, '\\$1');
    };

    function defaultToWhiteSpace(characters) {
      if (characters == null)
        return '\\s';
      else if (characters.source)
        return characters.source;
      else
        return '[' + escapeRegExp(characters) + ']';
    };

    pub.ltrim = function(str, characters) {
        var nativeTrimLeft = String.prototype.trimLeft;
        str = makeString(str);
        if (!characters && nativeTrimLeft) return nativeTrimLeft.call(str);
        characters = defaultToWhiteSpace(characters);
        return str.replace(new RegExp('^' + characters + '+'), '');
    };

    pub.trim = function(str, characters) {
        var nativeTrim = String.prototype.trim;
        str = makeString(str);
        if (!characters && nativeTrim) return nativeTrim.call(str);
        characters = defaultToWhiteSpace(characters);
        return str.replace(new RegExp('^' + characters + '+|' + characters + '+$', 'g'), '');
    };

    pub.rtrim = function(str, characters) {
        var nativeTrimRight = String.prototype.trimRight;
        str = makeString(str);
        if (!characters && nativeTrimRight) return nativeTrimRight.call(str);
        characters = defaultToWhiteSpace(characters);
        return str.replace(new RegExp(characters + '+$'), '');
    };

    /*
     * Based on Title Case 2.1 – http://individed.com/code/to-title-case/
     */
    pub.titleCase = function (str) {
        var smallWords = /^(de|da|das|do|dos|a)$/i;

        return str.replace(/[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g, function(match, index, title){
            if (index > 0 && index + match.length !== title.length &&
				match.search(smallWords) > -1 && title.charAt(index - 2) !== ":" &&
				(title.charAt(index + match.length) !== '-' || title.charAt(index - 1) === '-') &&
				title.charAt(index - 1).search(/[^\s-]/) < 0) {
				return match.toLowerCase();
            }

            return match.charAt(0).toUpperCase() + match.substr(1).toLowerCase();
        });
    };

    // String utils
    ////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////
    // DOM utils

    pub.showAlert = function(msg, success) {
        var $div = $('<div style="position: absolute; top: 0; width: 100%; z-index: 1050 !important;" ' +
                     'class="alert ' + (success ? 'alert-success' : 'alert-danger') + '">' +
                     msg + '</div>');
        $div.prependTo('body').fadeIn('fast');
        setTimeout(function() { $div.slideUp('slow', function() { $div.remove(); }); }, 2000);
    }

    pub.flash = function(elements) {
        var opacity = 100;
        var color = "255, 255, 148" // has to be in this format since we use rgba
        var interval = setInterval(function() {
            opacity -= 2;
            if (opacity <= 0) clearInterval(interval);
            $(elements).css({background: "rgba("+color+", "+opacity/100+")"});
        }, 30)
    };

    // DOM utils
    /////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////
    // Datatables utils

   /**
     * Used to render currency columns in datatables
     */
    pub.renderCurrencyColumn = function () {
        if (APPIconfig['datatables_currency_columns_euro']) {
            return $.fn.dataTable.render.number( '.', ',', 2, '', ' €' );
        }
        else {
            return $.fn.dataTable.render.number( '', '.', 2, '', '' );
        }
    }

    // Datatables utils
    /////////////////////////////////////////////////////////////////////////

	return pub;
}());